
import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import OL from './components';
import { UpdateLanguage, UpdateMenuStatus } from './redux/Action';
import { connect } from 'react-redux'
//css
import './css/App.css';
import 'ol/ol.css';

class Program extends React.Component {
  constructor() {
    super();
    this.state = {
      showMenu: false
    }
  }
  render() {
    // console.log(this.app_language)
    return (
      <div>
        <AppBar position="absolute" style={{ backgroundColor: '#1D2D44' }}>
          <Toolbar >
            <div>
              <a href='http://spectra.az' target='blank_'><img src='./Images/MapIcons/logoSP.png' alt='' style={{ maxHeight: '32px' }} /></a>
            </div>
            <Typography variant="h6" style={{ margin: 'auto' }}>
              Info Map
           </Typography>
            <div className='flagsContainer'>
              <img alt='' className='lFlags' src='/Images/MapIcons/flag/aze.png' onClick={
                () => {
                  this.props.setLanguage('aze');
                  localStorage.setItem('app_lng', 'aze')
                }
              } />
              <img alt='' className='lFlags' src='/Images/MapIcons/flag/rus.png' onClick={
                () => {
                  this.props.setLanguage('rus')
                  localStorage.setItem('app_lng', 'rus')
                }}
              />
              <img alt='' className='lFlags' src='/Images/MapIcons/flag/eng.png' onClick={() => {
                this.props.setLanguage('eng')
                localStorage.setItem('app_lng', 'eng')
              }} />
            </div>
            <IconButton edge="start" style={{ right: '10px', position: 'absolute' }} color="inherit" aria-label="menu" onClick={() => {
              this.props.setMenuStatus(!this.props.menuStatus)
              if (!document.getElementById('menuCover')) {
                document.getElementsByClassName('ol-zoom ol-unselectable ol-control')[0].style.right = '340px';
              } else {
                document.getElementsByClassName('ol-zoom ol-unselectable ol-control')[0].style.right = '8px';
              }
            }}>
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <OL />
      </div>
    );
  }
}


export default connect((state) => { return { lng: state.lng.name, menuStatus: state.menuStatus.status } }, { setLanguage: UpdateLanguage, setMenuStatus: UpdateMenuStatus })(Program);

