import React from 'react';
import { UpdateLegendStatus, UpdateLayers } from '../redux/Action';
import { connect } from 'react-redux';
//Openlayers
import { Map, View } from 'ol';
import { fromLonLat } from 'ol/proj';
//WMS layers
import * as allWmsLayers from '../OpenLayers/Layers';
//Components
import PopUpMemarilAbideleri from '../components/PopUp/PopUpMemarilAbideleri';
import PopUpTourismPoints from '../components/PopUp/PopUpTourismPoints';
import ZooPopUp from '../components/PopUp/ZooPopupCard'
import AccordionMenu from './AccordionMenu'
//Helper functions
import { ChoosePopUp } from '../Helper/BringPopUp';
import ResizeIcons from '../Helper/ResizeIcons';
import Legend from './Legend';
import { SetYourLocation } from '../Helper/YourLocation';
import { GetImage } from '../Helper/GetImageFromMap';
import LegendSwiCher from './LegendSwitcher'

//Import css;
import './css/index.css';

//Other
export class OL extends React.Component {
    constructor() {
        super();
        this.state = {
            showMenuBtn: true,
            mapName: '',
            PopUpTourismPoints: {
                name: ''
            },
            PopUpZooPoints: {
                name: '',
                image: ''
            },
            PopUpMemarilAbideleri: {
                name: '',
                adress: '',
                date: '',
                type_: '',
                wikipedia: '',
                image: ''
            }
        }

    }

    componentDidMount() {
        this.map = new Map({
            target: 'map',
            layers: Object.values(allWmsLayers),
            view: new View({
                projection: 'EPSG:3857',
                center: new fromLonLat([47.55, 40.20]),
                zoom: 8,
                maxZoom: 17,
                minZoom:7
            })
        });
        this.map.on('pointermove', (e) => {
            const pixel = this.map.getEventPixel(e.originalEvent);
            const hit = this.map.hasFeatureAtPixel(pixel);
            document.getElementById('map').style.cursor = hit ? 'pointer' : '';
        });

        // this.props.setMap(this.map);
        this.map.on('click', (evt) => {
            var feature = this.map.forEachFeatureAtPixel(evt.pixel, feature => {
                return feature;
            });
            if (feature) {
                ChoosePopUp(this.map, this, feature);
            }
        });

        this.map.on('moveend', (e) => {
            ResizeIcons(allWmsLayers.Zoocografi_Points, e);
            ResizeIcons(allWmsLayers.Turizm_Points, e, 1.5);
            ResizeIcons(allWmsLayers.Turizm_Abideler, e, 1.5);
            ResizeIcons(allWmsLayers.Fiziki_Points, e, 1);
            ResizeIcons(allWmsLayers.Torpaq_Points, e, 0.15);
        })
    }
    handleLocation = () => {
        SetYourLocation(this.map)
    }
    handleGetImage = () => {
        GetImage(this.map)
    }
    render() {
        return (
            <div id='map' className='map'>
                <a className="btn-floating  waves-effect waves-light blue-grey darken-4" id='btnMnu' href="javascript:void(0)" >
                    <i className="material-icons">{/*<FaEllipsisV fontSize='20px' />*/}</i>
                </a>
                {this.props.legendStatus ? null : <LegendSwiCher />}
                {this.props.legendStatus ? <Legend mapName={this.props.mapName} /> : null}
                {this.props.menuStatus ? <AccordionMenu SetLocation={this.handleLocation} TakeImage={this.handleGetImage} /> : null}
                <div>
                    {/* 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node. 
                ashagida olan popuplar react xaricinde idare olundugu uchun, yəni css ile achilib baglandigi uchun yuxxaridaki xetani alir
                ona gorede bu popuplari DIV icherisine alarken bu proeblem aradan qalxir
            */}
                    <PopUpTourismPoints name={this.state.PopUpTourismPoints.name} />
                    <PopUpMemarilAbideleri data={this.state.PopUpMemarilAbideleri} />
                </div>
                <ZooPopUp data={this.state.PopUpZooPoints} />
            </div>
        )
    }
}
const mapDispatchToProps = {
    setLegendStatus: UpdateLegendStatus,
    UpdateLayers: UpdateLayers
}
export default connect((state) => { return { legendStatus: state.legendStatus.status, menuStatus: state.menuStatus.status, mapName: state.mapName.name, Layers: state.mapLayers.Layers } }, mapDispatchToProps)(OL)
