import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        height: 159
    },
}));


export default function SimpleTable({ data }) {
    const classes = useStyles();

    return (
        <Paper className={classes.root}>
            <Table className={classes.table}>
                <TableBody>
                    <TableRow >
                        <TableCell align="left">Ad :</TableCell>
                        <TableCell align="left">{data ? data.name : ''}</TableCell>
                    </TableRow>
                    <TableRow >
                        <TableCell align="left">Ünvan :</TableCell>
                        <TableCell align="left">{data ? data.adress : ''}</TableCell>
                    </TableRow>
                    <TableRow >
                        <TableCell align="left">Tarix :</TableCell>
                        <TableCell align="left">{data ? data.date : ''}</TableCell>
                    </TableRow>
                    <TableRow >
                        <TableCell align="left">Növü :</TableCell>
                        <TableCell align="left">{data ? data.type_ : ''}</TableCell>
                    </TableRow>
                    <TableRow >
                        <TableCell align="left">Ətraflı :</TableCell>
                        <TableCell align="left"><a className='wlnk' href={data ? data.wikiPath : ''} target='blank_' rel="noreferrer">Wikipedia</a></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Paper>
    );
}
