import React, { Component } from 'react'
import {
    TableCell,
    TableRow,

} from '@material-ui/core/';
export class IconicLegend extends Component {

    render() {
        return (
            <TableRow style={{ height: '100px', padding: 0 }}>
                <TableCell style={{ lineHeight: '100px', width: '20%' }}>
                    <div className='tblFrstClmIcon'>
                        <img src={this.props.data.iconPath} style={{maxHeight:'40px',maxWidth:'40px'}} alt=''/>
                    </div>
                </TableCell>
                <TableCell style={{ width: '80%' }}>
                    <div className='tblSecondClmIcon'>
                        {this.props.data.fname}
                    </div>
                </TableCell>
            </TableRow>
        )
    }
}

export default IconicLegend
