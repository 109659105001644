import { combineReducers } from 'redux';
import { LegendStatus } from './legend.status.reducer';
import { MenuStatus } from './menu.status.reducer';
import { MapName } from './azmapName.reducer';
import { LanguageReducer } from './language.reducer';
import { PandingStatus } from './requestPanding.reducer.js';
import { LayersReducer } from './layers.reducer'
export const RootReducer = combineReducers({
    legendStatus: LegendStatus,
    menuStatus: MenuStatus,
    mapName: MapName,
    lng: LanguageReducer,
    reqPanding: PandingStatus,
    mapLayers: LayersReducer
});