import React, { Fragment } from 'react';
import {
    Paper,
    Table,
    TableBody
} from '@material-ui/core/';
import { Close } from '@material-ui/icons'
import { Fetching } from '../Helper/FetchDataForLegend'
import PolygonalLegend from './PolygonalLegend';
import IconicLegend from './IconicLegend';
import { UpdateLegendStatus } from '../redux/Action';
import { connect } from 'react-redux';
//Css
import './css/Legend.css'
class Legend extends React.Component {
    constructor() {
        super();
        this.state = {
            mapName: '',
            legendPointData: [],
            legendPolygonData: []
        }
        this.sendLegendState = this.sendLegendState.bind(this);
    }
    componentWillReceiveProps(nextprops) {
        if (this.state.mapName !== nextprops.mapName) {
            this.setState({ mapName: nextprops.mapName })
            Fetching(nextprops.mapName, this)
        }
        //Change Legend content
    }
    componentDidMount() {
        Fetching(this.props.mapName, this)
    }
    
    sendLegendState(state) {
        return this.props.getLegendState(state);
    }
    render() {
        return (
            <Fragment >
                <Paper id='MapLegend' >
                    <div className='legendHeader' style={{backgroundColor:'#1D2D44',cursor:'pointer',color:'#fff'}} onClick={()=>{this.props.setLegendStatus(!this.props.legendStatus)}}>
                        <h4 style={{ display: 'inline-block', }}>LEGENDA</h4>
                        <Close style={{ float: 'right', cursor: 'pointer',color:'#purple' }} onClick={() => {
                            this.props.setLegendStatus(!this.props.legendStatus);
                        }} />
                    </div>
                    <div className='legendCover'>
                        <Table style={{ width: '100%' }}>
                            <TableBody>
                                {
                                    this.state.legendPointData.map((pointData, i) => {
                                        return (
                                            <IconicLegend data={pointData} key={i} />
                                        )
                                    })
                                }
                                {this.state.legendPolygonData.length > 0 ? this.state.legendPolygonData.map((polyData, j) => {
                                    return (
                                        <PolygonalLegend data={polyData} key={j} />
                                    )
                                }) : null}
                            </TableBody>
                        </Table>
                    </div>
                </Paper>
            </Fragment>
        )
    }
}

export default connect((state) => { return { legendStatus: state.legendStatus.status,mapName:state.mapName.name } }, { setLegendStatus: UpdateLegendStatus })(Legend)