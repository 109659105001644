import React from 'react';
import ReactDOM from 'react-dom';
import Program from './Program';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { RootReducer } from './redux/reducer'
import { createStore } from 'redux';
//import materialize.css
const myStore = createStore(RootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
ReactDOM.render(<Provider store={myStore}><Program /></Provider>, document.querySelector('#root'));
serviceWorker.unregister();
