import React from 'react';
import { connect } from 'react-redux';
import { UpdateLegendStatus } from '../redux/Action';

const LegendSwitcher = (props) => {
    return (
        <div style={{ position: 'fixed', width: '120px',  textAlign: 'center', height: '30px', bottom: 24, left: 4, zIndex: 9999, backgroundColor: '#1D2D44', lineHeight: '30px', cursor: 'pointer' }} onClick={() => {
            props.setLegend(true)
        }}>
            <h4 style={{ display: 'inline-block', color: '#fff', fontWeight: 'bold' }}>LEGENDA</h4>
        </div>
    )
}
export default connect(null, { setLegend: UpdateLegendStatus })(LegendSwitcher);