export default (allWmsLayers, e, factor = 1) => {
    if (allWmsLayers.getVisible()) {
        allWmsLayers.getSource().getFeatures().forEach(feature => {
            let mapZoom = e.map.getView().getZoom();
            if (mapZoom < 6) {
                feature.getStyle().getImage().scale_ = 0.001 * factor;
                allWmsLayers.getSource().refresh();
            }
            if (mapZoom === 6) {
                feature.getStyle().getImage().scale_ = 0.05 * factor;
                allWmsLayers.getSource().refresh();
            }
            if (mapZoom === 7) {
                feature.getStyle().getImage().scale_ = 0.10 * factor;
                allWmsLayers.getSource().refresh();
            }
            if (mapZoom === 8) {
                feature.getStyle().getImage().scale_ = 0.20 * factor;
                allWmsLayers.getSource().refresh();
            } if (mapZoom === 9) {
                feature.getStyle().getImage().scale_ = 0.40 * factor;
                allWmsLayers.getSource().refresh();
            } if (mapZoom === 10) {
                feature.getStyle().getImage().scale_ = 0.60 * factor;
                allWmsLayers.getSource().refresh();
            }
            if (mapZoom > 10) {
                feature.getStyle().getImage().scale_ = 0.80 * factor;
                allWmsLayers.getSource().refresh();
            }
        })
    }
}