import Overlay from 'ol/Overlay';
export const ChoosePopUp = (map, that, feature) => {
    try {
        let controlTheFeature = feature.getId();
    let splitedID = controlTheFeature.split('.');
    let coordinates = feature.getGeometry().getCoordinates();
    let overlayMA;
    let overlayTourism;
    let overlayZoo;
    if (splitedID[0] === 'turizm_abideler') {
        document.getElementById('popupWrapTrsmIncs').style.display = 'none';
        document.getElementById('popupWrapZooIncs').style.display = 'none';
        //Set Memarliq abideleri oveerlay
        let theMaPopUp = document.getElementById('popupWrap');
        theMaPopUp.style.display = 'block';
        overlayMA = new Overlay({
            element: theMaPopUp,
            autoPan: true,
            stopEvent: false,
            autoPanAnimation: {
                duration: 250
            }
        });
        that.setState({
            PopUpMemarilAbideleri: {
                name: feature.get('fname'),
                date: feature.get('date'),
                city: feature.get('city'),
                adress: feature.get('adress'),
                type_: feature.get('type'),
                wikiPath: feature.get('viki_path'),
                image: feature.get('orgImgPath')
            }
        })
        map.removeOverlay(overlayTourism);
        map.addOverlay(overlayMA);
        overlayMA.setPosition(coordinates);
    }
    if (splitedID[0] === 'zoocografi_point') {
        document.getElementById('popupWrapTrsmIncs').style.display = 'none';
        document.getElementById('popupWrap').style.display = 'none';

        //Set Memarliq abideleri oveerlay
        let theZooPopUp = document.getElementById('popupWrapZooIncs');
        theZooPopUp.style.display = 'block';
        overlayZoo = new Overlay({
            element: theZooPopUp,
            autoPan: true,
            stopEvent: false,
            autoPanAnimation: {
                duration: 250
            }
        });
        that.setState({
            PopUpZooPoints: {
                name: feature.get('fname'),
                image: feature.get('orgImg')
            }
        })
        map.removeOverlay(overlayTourism);
        map.addOverlay(overlayZoo);
        overlayZoo.setPosition(coordinates);
    }
    if (splitedID[0] === 'turizm_points' || splitedID[0] === 'fiziki_points' ||splitedID[0] === 'torpaq_point') {
        //Disable other popUp
        document.getElementById('popupWrap').style.display = 'none';
        // document.getElementById('').style.visibility = 'hidden';
        let theToutismPopUpPoints = document.getElementById('popupWrapTrsmIncs');
        theToutismPopUpPoints.style.display = 'block';
        overlayTourism = new Overlay({
            element: theToutismPopUpPoints,
            autoPan: true,
            stopEvent: false,
            autoPanAnimation: {
                duration: 250
            }
        });
        that.setState({
            PopUpTourismPoints: {
                name: feature.get('fname')
            }
        })
        map.removeOverlay(overlayMA);
        map.addOverlay(overlayTourism);
        overlayTourism.setPosition(coordinates);
    }
    } catch  {
       
    }
}