const defaultState = {
    name:'aze'
}
export const LanguageReducer = (state = defaultState, action) => {
    switch (action.type) {
        case 'UPDATE_LANGUAGE':
            return {
                ...state,
                name: action.payload
            }
        default:
            return state;
    }
}