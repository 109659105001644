const defaultState = {
    Layers: {
        SL_bitkiler_PN: false,
        Turizm_Points: true,
        Turizm_Abideler: true,
        Fiziki_Points: false,
        Torpaq_Points: false,
        SL_fiziki_Elevation_LE: false,
        SL_fiziki_daimiqar_LE: false,
        SL_geomorfoloji_PN: false,
        SL_geomorfoloji_LE: false,
        SL_geomorfoloji_relyef_PT: false,
        SL_iqlim_PN: false,
        SL_iqlim_tempuratur_LE: false,
        SL_mesheler_PN: false,
        SL_orografiya_dac_PN: false,
        SL_orografiya_silsileler_LE: false,
        SL_torpaq_PN: false,
        SL_zocografi_PN: false,
        Zoocografi_Points: true,
        SL_zoocografi_qoruqlar_PN: true
    }
}
export const LayersReducer = (state = defaultState, action) => {
    switch (action.type) {
        case 'UPDATE_LAYERS':
            return {
                ...state,
                Layers: action.payload
            }
        default:
            return state;
    }
}