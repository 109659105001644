
import Feature from 'ol/Feature';
// import { circular } from 'ol/geom/Polygon';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { fromLonLat } from 'ol/proj';

export const SetYourLocation = (map) => {
    const source = new VectorSource();
    const layer = new VectorLayer({
        source: source
    });
    source.clear(true)
    map.addLayer(layer);
    navigator.geolocation.getCurrentPosition((pos) => {
        let coords = [pos.coords.longitude, pos.coords.latitude];
        // let accuracy = circular(coords, pos.coords.accuracy);
        source.addFeatures([
            // new Feature(accuracy.transform('EPSG:4326', this.map.getView().getProjection())),
            new Feature(new Point(fromLonLat(coords)))
        ]);
        if (!source.isEmpty()) {
            map.getView().fit(source.getExtent(), {
                maxZoom: 18,
                duration: 500
            });
            layer.setZIndex(9999);
        }
    }, function (error) {
        alert(`ERROR: ${error.message}`);
    }, {
            enableHighAccuracy: true
        });
}