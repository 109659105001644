import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
//css
import './CSS/PopUpZooPoint.css'
export class PopUpZooPoints extends Component {
    constructor() {
        super();
        this.state = {
            data: {
                name: '',
                image: ''
            }
        }
    }
    render() {
        return (
            <div id='popupWrapZooIncs'>
                <div id='closePanel'>
                    <div id='closButton' onClick={(e) => {
                        document.getElementById('popupWrapZooIncs').style.display = '';
                    }
                    }>x</div>
                </div>
                <div id='popUpBody'>
                    <div id='OrgImage'>
                        {this.props.data ? <img src={this.props.data.image} alt='' /> : < CircularProgress color="secondary" />}
                    </div>
                    <div id='popContentZoo' className='zooPntsTextAditional'>
                        <span id='zooPntsText'>{this.props.data.name}</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default PopUpZooPoints
