import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';
import PopUpMaTable from './PopUpMaTable';
//css
import './CSS/PopUpMemarilAbideleri.css';
export class PopUpMemarilAbideleri extends React.PureComponent {
    constructor() {
        super();
    }

    render() {
        return (
            <div id='popupWrap'>
                <div id='closePanel'>
                    <div id='closButton' onClick={(e) => {
                        let theMaPopUp = document.getElementById('popupWrap');
                        theMaPopUp.style.display = ''
                    }
                    }>x</div>
                </div>
                <div id='popUpImage'>
                    {this.props.data? <img src={this.props.data.image} className='pImage' alt='' /> :< CircularProgress color="secondary" />}
                </div>
                <div id='popContent'>
                    <PopUpMaTable data={this.props.data} />

                </div>
            </div>
        )
    }
}

export default PopUpMemarilAbideleri;
