export const UpdateMenuStatus = (data) => {
    return{
        type:'UPDATE_MENU_STATUS',
        payload:data
    }    
}
export const UpdateLegendStatus = (data) => {
    return{
        type:'UPDATE_LEGEND_STATUS',
        payload:data
    }    
}
export const UpdateMapMame = (data) => {
    return{
        type:'UPDATE_MAP_NAME',
        payload:data
    }    
}
export const UpdateLanguage = (data) => {
    return{
        type:'UPDATE_LANGUAGE',
        payload:data
    }    
}
export const UpdateRequestPanding = (data) => {
    return{
        type:'UPDATE_REQUIEST_PANDING',
        payload:data
    }    
}
export const UpdateLayers = (data) => {
    return{
        type:'UPDATE_LAYERS',
        payload:data
    }    
}
