const defaultState = {
    status:false
}
export const MenuStatus = (state = defaultState, action) => {
    switch (action.type) {
        case 'UPDATE_MENU_STATUS':
            return {
                ...state,
                status: action.payload
            }
        default:
            return state;
    }
}