import * as allLayers from '../OpenLayers/Layers';
const SwitchMap = (name) => {
    name = name.trim();
    if (name.includes('Bitki örtüyü xəritəsi')) {
        allLayers.SL_bitkiler_PN.setVisible(true);    
        allLayers.SL_torpaq_PN.setVisible(false);
        allLayers.SL_fiziki_Elevation_LE.setVisible(false);
        allLayers.SL_geomorfoloji_PN.setVisible(false);
        allLayers.SL_iqlim_PN.setVisible(false);
        allLayers.SL_zocografi_PN.setVisible(false);
        allLayers.SL_zoocografi_qoruqlar_PN.setVisible(false);
        allLayers.SL_orografiya_dac_PN.setVisible(false);
        allLayers.SL_mesheler_PN.setVisible(false);
        allLayers.SL_fiziki_daimiqar_LE.setVisible(false);
        allLayers.SL_geomorfoloji_LE.setVisible(false);
        allLayers.SL_iqlim_tempuratur_LE.setVisible(false);
        allLayers.SL_orografiya_silsileler_LE.setVisible(false);
        allLayers.SL_geomorfoloji_relyef_PT.setVisible(false);
        allLayers.SL_torpaq_PT.setVisible(false);
        allLayers.Turizm_Abideler.setVisible(false);
        allLayers.Turizm_Points.setVisible(false);
        allLayers.Fiziki_Points.setVisible(false);
        allLayers.Torpaq_Points.setVisible(false);
        allLayers.Zoocografi_Points.setVisible(false);

    } if (name.includes('Turistik xəritə')) {
        allLayers.SL_bitkiler_PN.setVisible(false);    
        allLayers.SL_torpaq_PN.setVisible(false);
        allLayers.SL_fiziki_Elevation_LE.setVisible(false);
        allLayers.SL_geomorfoloji_PN.setVisible(false);
        allLayers.SL_iqlim_PN.setVisible(false);
        allLayers.SL_zocografi_PN.setVisible(false);
        allLayers.SL_zoocografi_qoruqlar_PN.setVisible(false);
        allLayers.SL_orografiya_dac_PN.setVisible(false);
        allLayers.SL_mesheler_PN.setVisible(true);
        allLayers.SL_fiziki_daimiqar_LE.setVisible(false);
        allLayers.SL_geomorfoloji_LE.setVisible(false);
        allLayers.SL_iqlim_tempuratur_LE.setVisible(false);
        allLayers.SL_orografiya_silsileler_LE.setVisible(false);
        allLayers.SL_geomorfoloji_relyef_PT.setVisible(false);
        allLayers.SL_torpaq_PT.setVisible(false);
        allLayers.Turizm_Abideler.setVisible(true);
        allLayers.Turizm_Points.setVisible(true);
        allLayers.Fiziki_Points.setVisible(false);
        allLayers.Torpaq_Points.setVisible(false);
        allLayers.Zoocografi_Points.setVisible(false);

    } if (name.includes('Geomorfoloji xəritə')) {
        allLayers.SL_bitkiler_PN.setVisible(false);    
        allLayers.SL_torpaq_PN.setVisible(false);
        allLayers.SL_fiziki_Elevation_LE.setVisible(false);
        allLayers.SL_geomorfoloji_PN.setVisible(true);
        allLayers.SL_iqlim_PN.setVisible(false);
        allLayers.SL_zocografi_PN.setVisible(false);
        allLayers.SL_zoocografi_qoruqlar_PN.setVisible(false);
        allLayers.SL_orografiya_dac_PN.setVisible(false);
        allLayers.SL_mesheler_PN.setVisible(false);
        allLayers.SL_fiziki_daimiqar_LE.setVisible(false);
        allLayers.SL_geomorfoloji_LE.setVisible(true);
        allLayers.SL_iqlim_tempuratur_LE.setVisible(false);
        allLayers.SL_orografiya_silsileler_LE.setVisible(false);
        allLayers.SL_geomorfoloji_relyef_PT.setVisible(true);
        allLayers.SL_torpaq_PT.setVisible(false);
        allLayers.Turizm_Abideler.setVisible(false);
        allLayers.Turizm_Points.setVisible(false);
        allLayers.Fiziki_Points.setVisible(false);
        allLayers.Torpaq_Points.setVisible(false);
        allLayers.Zoocografi_Points.setVisible(false);

    } if (name.includes('İqlim xəritəsi')) {
        allLayers.SL_bitkiler_PN.setVisible(false);    
        allLayers.SL_torpaq_PN.setVisible(false);
        allLayers.SL_fiziki_Elevation_LE.setVisible(false);
        allLayers.SL_geomorfoloji_PN.setVisible(false);
        allLayers.SL_iqlim_PN.setVisible(true);
        allLayers.SL_zocografi_PN.setVisible(false);
        allLayers.SL_zoocografi_qoruqlar_PN.setVisible(false);
        allLayers.SL_orografiya_dac_PN.setVisible(false);
        allLayers.SL_mesheler_PN.setVisible(false);
        allLayers.SL_fiziki_daimiqar_LE.setVisible(false);
        allLayers.SL_geomorfoloji_LE.setVisible(false);
        allLayers.SL_iqlim_tempuratur_LE.setVisible(true);
        allLayers.SL_orografiya_silsileler_LE.setVisible(false);
        allLayers.SL_geomorfoloji_relyef_PT.setVisible(false);
        allLayers.SL_torpaq_PT.setVisible(false);
        allLayers.Turizm_Abideler.setVisible(false);
        allLayers.Turizm_Points.setVisible(false);
        allLayers.Fiziki_Points.setVisible(false);
        allLayers.Torpaq_Points.setVisible(false);
        allLayers.Zoocografi_Points.setVisible(false);

    } if (name.includes('Orografiya xəritəsi')) {
        allLayers.SL_bitkiler_PN.setVisible(false);    
        allLayers.SL_torpaq_PN.setVisible(false);
        allLayers.SL_fiziki_Elevation_LE.setVisible(false);
        allLayers.SL_geomorfoloji_PN.setVisible(false);
        allLayers.SL_iqlim_PN.setVisible(false);
        allLayers.SL_zocografi_PN.setVisible(false);
        allLayers.SL_zoocografi_qoruqlar_PN.setVisible(false);
        allLayers.SL_orografiya_dac_PN.setVisible(true);
        allLayers.SL_mesheler_PN.setVisible(true);
        allLayers.SL_fiziki_daimiqar_LE.setVisible(true);
        allLayers.SL_geomorfoloji_LE.setVisible(false);
        allLayers.SL_iqlim_tempuratur_LE.setVisible(false);
        allLayers.SL_orografiya_silsileler_LE.setVisible(true);
        allLayers.SL_geomorfoloji_relyef_PT.setVisible(false);
        allLayers.SL_torpaq_PT.setVisible(false);
        allLayers.Turizm_Abideler.setVisible(false);
        allLayers.Turizm_Points.setVisible(false);
        allLayers.Fiziki_Points.setVisible(true);
        allLayers.Torpaq_Points.setVisible(false);
        allLayers.Zoocografi_Points.setVisible(false);

    } if (name.includes('Torpaqların ekoloji qiymətləndirməsi')) {
        allLayers.SL_bitkiler_PN.setVisible(false);    
        allLayers.SL_torpaq_PN.setVisible(true);
        allLayers.SL_fiziki_Elevation_LE.setVisible(false);
        allLayers.SL_geomorfoloji_PN.setVisible(false);
        allLayers.SL_iqlim_PN.setVisible(false);
        allLayers.SL_zocografi_PN.setVisible(false);
        allLayers.SL_zoocografi_qoruqlar_PN.setVisible(false);
        allLayers.SL_orografiya_dac_PN.setVisible(false);
        allLayers.SL_mesheler_PN.setVisible(false);
        allLayers.SL_fiziki_daimiqar_LE.setVisible(false);
        allLayers.SL_geomorfoloji_LE.setVisible(false);
        allLayers.SL_iqlim_tempuratur_LE.setVisible(false);
        allLayers.SL_orografiya_silsileler_LE.setVisible(false);
        allLayers.SL_geomorfoloji_relyef_PT.setVisible(false);
        allLayers.SL_torpaq_PT.setVisible(false);
        allLayers.Turizm_Abideler.setVisible(false);
        allLayers.Turizm_Points.setVisible(false);
        allLayers.Fiziki_Points.setVisible(false);
        allLayers.Torpaq_Points.setVisible(true);
        allLayers.Zoocografi_Points.setVisible(false);

    } if (name.includes('Zoocografi xəritə')) {
        allLayers.SL_bitkiler_PN.setVisible(false);    
        allLayers.SL_torpaq_PN.setVisible(false);
        allLayers.SL_fiziki_Elevation_LE.setVisible(false);
        allLayers.SL_geomorfoloji_PN.setVisible(false);
        allLayers.SL_iqlim_PN.setVisible(false);
        allLayers.SL_zocografi_PN.setVisible(true);
        allLayers.SL_zoocografi_qoruqlar_PN.setVisible(true);
        allLayers.SL_orografiya_dac_PN.setVisible(false);
        allLayers.SL_mesheler_PN.setVisible(false);
        allLayers.SL_fiziki_daimiqar_LE.setVisible(false);
        allLayers.SL_geomorfoloji_LE.setVisible(false);
        allLayers.SL_iqlim_tempuratur_LE.setVisible(false);
        allLayers.SL_orografiya_silsileler_LE.setVisible(false);
        allLayers.SL_geomorfoloji_relyef_PT.setVisible(false);
        allLayers.SL_torpaq_PT.setVisible(false);
        allLayers.Turizm_Abideler.setVisible(false);
        allLayers.Turizm_Points.setVisible(false);
        allLayers.Fiziki_Points.setVisible(false);
        allLayers.Torpaq_Points.setVisible(false);
        allLayers.Zoocografi_Points.setVisible(true);

    } if (name.includes('Bingmap Peyk')) {
        allLayers.bingMapsAerial.setVisible(true);
        allLayers.bingMapsRoad.setVisible(false);
        allLayers.googleSatellite.setVisible(false);
        allLayers.googleRoad.setVisible(false);
        allLayers.openStreet.setVisible(false);
        allLayers.GoMap.setVisible(false);

    } if (name.includes('Bingmap Yollar')) {
        allLayers.bingMapsAerial.setVisible(false);
        allLayers.bingMapsRoad.setVisible(true);
        allLayers.googleSatellite.setVisible(false);
        allLayers.googleRoad.setVisible(false);
        allLayers.openStreet.setVisible(false);
        allLayers.GoMap.setVisible(false);

    } if (name.includes('Google Peyk')) {
        allLayers.bingMapsAerial.setVisible(false);
        allLayers.bingMapsRoad.setVisible(false);
        allLayers.googleSatellite.setVisible(true);
        allLayers.googleRoad.setVisible(false);
        allLayers.openStreet.setVisible(false);
        allLayers.GoMap.setVisible(false);
    } if (name.includes('Google Yollar')) {
        allLayers.bingMapsAerial.setVisible(false);
        allLayers.bingMapsRoad.setVisible(false);
        allLayers.googleSatellite.setVisible(false);
        allLayers.googleRoad.setVisible(true);
        allLayers.openStreet.setVisible(false);
        allLayers.GoMap.setVisible(false);

    }/*if (name.includes('Yandex')) {
        allLayers.bingMapsAerial.setVisible(false);
        allLayers.bingMapsRoad.setVisible(false);
        allLayers.googleSatellite.setVisible(false);
        allLayers.googleRoad.setVisible(false);
        allLayers.openStreet.setVisible(false);
        allLayers.GoMap.setVisible(false);

    }*/if (name.includes('OpenStreetMap')) {
        allLayers.bingMapsAerial.setVisible(false);
        allLayers.bingMapsRoad.setVisible(false);
        allLayers.googleSatellite.setVisible(false);
        allLayers.googleRoad.setVisible(false);
        allLayers.openStreet.setVisible(true);
        allLayers.GoMap.setVisible(false);

    } if (name.includes('GoMap')) {
        allLayers.bingMapsAerial.setVisible(false);
        allLayers.bingMapsRoad.setVisible(false);
        allLayers.googleSatellite.setVisible(false);
        allLayers.googleRoad.setVisible(false);
        allLayers.openStreet.setVisible(false);
        allLayers.GoMap.setVisible(true);
    }

}
export default SwitchMap;

