import React, { Component } from 'react'
import {
    TableCell,
    TableRow,

} from '@material-ui/core/';
export class PolygonalLegend extends Component {    
    render() {
        return (
            <TableRow style={{ height: '100px', padding: 0 }}>
                <TableCell style={{ lineHeight: '100px',width:'20%' }}>
                    <div className='tblFrstClm' style={{ backgroundColor: this.props.data.color }}>
                        {this.props.data.nomresi}                        
                    </div>
                </TableCell>
                <TableCell style={{ width: '80%' }}>
                    <div className='tblSecondClm'>
                        {this.props.data.fname}
                    </div>
                </TableCell>
            </TableRow>
        )
    }
}

export default PolygonalLegend
