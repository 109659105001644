//Openayers
import 'ol/ol.css';
import TileLayer from 'ol/layer/Tile';
import WMS from 'ol/source/TileWMS';
import { BingMaps, OSM, XYZ } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { GeoJSON, WFS } from 'ol/format';
import { Style, Icon } from 'ol/style';
// import proj from 'proj';
// import {register} from 'ol/proj/proj4';

//Custom
import { pathWms,pathWfs } from '../../Helper/config';

//WMS
// Google
export const googleRoad = new TileLayer({
    'title': 'Google Maps Road',
    'type': 'base',
    'name': 'road',
    'visible': false,
    'opacity': 1.000000,
    source: new XYZ({
        // attributions: [new Attribution({ html: '<a href=""></a>' })],
        url: 'https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
    }),
    name:'googleRoad'
});


export const googleSatellite = new TileLayer({
    'title': 'Google Maps Aero',
    'type': 'base',
    'name': 'aero',
    'visible': false,
    'opacity': 1.000000,
    source: new XYZ({
        // attributions: [new Attribution({ html: '<a href=""></a>' })],
        url: 'https://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga'
    }),
    name:'googleAero'
});    //BingMap
export const bingMapsRoad = new TileLayer({
    preload: Infinity,
    source: new BingMaps({
        key: 'AqQfMkz44PDSPUR3dRzqo3OiK0_J4zoLK_F2SJPxd1XlyMYrioEgl_ThyS6n6edY',
        imagerySet: 'Road',
        culture: 'fr-FR'
    }),
    visible: false,
    name:'bingMapsRoad'
});

export const bingMapsAerial = new TileLayer({
    preload: Infinity,
    source: new BingMaps({
        key: 'AqQfMkz44PDSPUR3dRzqo3OiK0_J4zoLK_F2SJPxd1XlyMYrioEgl_ThyS6n6edY',
        imagerySet: 'Aerial',
    }),
    visible: false,
    name:'bingMapsAerial'
});
//Openstreet
export const openStreet = new TileLayer({
    source: new OSM(),
    visible: false,
    name:'openStreet'
})
export const GoMap = new TileLayer({
    'visible': false,
    'opacity': 1.000000,
    source: new XYZ({
        // attributions: [new Attribution({ html: '<a href=""></a>' })],
        url: 'http://gomap.az/info/xyz.do?lng=az&x={x}&y={y}&z={z}&f=png'
    }),
    name:'GoMap'
});
//Yandex
// var yanexd = [-20037508.342789244, -20037508.342789244, 20037508.342789244, 20037508.342789244];
// register.defs('EPSG:3395', '+proj=merc +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs');
// proj.get('EPSG:3395').setExtent(yanexd);

// var yandexMap = new TileLayer({
//     source: new XYZ({
//         url: 'http://vec01.maps.yandex.net/tiles?l=map&x={x}&y={y}&z={z}',
//         projection: 'EPSG:3395',
//         tileGrid: ol.tilegrid.createXYZ({
//             extent: yanexd
//         }),
//         visible: false
//     })
// })

export const SL_bitkiler_PN = new TileLayer({
    source: new WMS({
        projection: 'EPSG:3857',
        url: pathWms,
        params: {
            'LAYERS': 'bitgiler_polygon',
            'FORMAT': 'image/png',
            'VERSION': '1.1.1',
            'TILED': true
        },
        serverType: 'geoserver',
        crossOrigin: 'anonymous'
    }),
    visible: false,
    name: 'SL_bitkiler_PN'
});
export const SL_torpaq_PN = new TileLayer({
    source: new WMS({
        projection: 'EPSG:3857',
        url: pathWms,
        params: {
            'LAYERS': 'torpaq_polygon',
            'FORMAT': 'image/png',
            'VERSION': '1.1.1',
            'TILED': true
        },
        serverType: 'geoserver',
        crossOrigin: 'anonymous'
    }),
    visible: false,
    name: 'SL_torpaq_PN'
});
export const SL_fiziki_Elevation_LE = new TileLayer({
    source: new WMS({
        projection: 'EPSG:3857',
        url: pathWms,
        params: {
            'LAYERS': 'fiziki_poly_elevations',
            'FORMAT': 'image/png',
            'VERSION': '1.1.1',
            'TILED': true
        },
        serverType: 'geoserver',
        crossOrigin: 'anonymous'
    }),
    visible: false,
    name: 'SL_fiziki_Elevation_LE'
});
export const SL_geomorfoloji_PN = new TileLayer({
    source: new WMS({
        projection: 'EPSG:3857',
        url: pathWms,
        params: {
            'LAYERS': 'geomorfoloji_polygon',
            'FORMAT': 'image/png',
            'VERSION': '1.1.1',
            'TILED': true
        },
        serverType: 'geoserver',
        crossOrigin: 'anonymous'
    }),
    visible: false,
    name: 'SL_geomorfoloji_PN'
});
export const SL_iqlim_PN = new TileLayer({
    source: new WMS({
        projection: 'EPSG:3857',
        url: pathWms,
        params: {
            'LAYERS': 'iqlim_polygon',
            'FORMAT': 'image/png',
            'VERSION': '1.1.1',
            'TILED': true
        },
        serverType: 'geoserver',
        crossOrigin: 'anonymous'
    }),
    visible: false,
    name: 'SL_iqlim_PN'
});
export const SL_zocografi_PN = new TileLayer({
    source: new WMS({
        projection: 'EPSG:3857',
        url: pathWms,
        params: {
            'LAYERS': 'zoocografi_polygon',
            'FORMAT': 'image/png',
            'VERSION': '1.1.1',
            'TILED': true
        },
        serverType: 'geoserver',
        crossOrigin: 'anonymous'
    }),
    visible: false,
    name: 'SL_zocografi_PN'
});
export const SL_zoocografi_qoruqlar_PN = new TileLayer({
    source: new WMS({
        projection: 'EPSG:3857',
        url: pathWms,
        params: {
            'LAYERS': 'zoocografi_qoruqlar_polygon',
            'FORMAT': 'image/png',
            'VERSION': '1.1.1',
            'TILED': true
        },
        serverType: 'geoserver',
        crossOrigin: 'anonymous'
    }),
    visible: false,
    name: 'SL_zoocografi_qoruqlar_PN'
});

export const SL_geomorfoloji_relyef_PT = new TileLayer({
    source: new WMS({
        projection: 'EPSG:3857',
        url: pathWms,
        params: {
            'LAYERS': 'geomorfoloji_relyef_point',
            'FORMAT': 'image/png',
            'VERSION': '1.1.1',
            'TILED': true
        },
        serverType: 'geoserver',
        crossOrigin: 'anonymous'
    }),
    visible: false,
    name: 'SL_geomorfoloji_relyef_PT'
});
export const SL_torpaq_PT = new TileLayer({
    source: new WMS({
        projection: 'EPSG:3857',
        url: pathWms,
        params: {
            'LAYERS': 'torpaq_point',
            'FORMAT': 'image/png',
            'VERSION': '1.1.1',
            'TILED': true
        },
        serverType: 'geoserver',
        crossOrigin: 'anonymous'
    }),
    visible: false,
    name: 'SL_torpaq_PT'
});
export const SL_orografiya_dac_PN = new TileLayer({
    source: new WMS({
        projection: 'EPSG:3857',
        url: pathWms,
        params: {
            'LAYERS': 'orografiya_dac_polygon',
            'FORMAT': 'image/png',
            'VERSION': '1.1.1',
            'TILED': true
        },
        serverType: 'geoserver',
        crossOrigin: 'anonymous'
    }),
    visible: false,
    name: 'SL_orografiya_dac_PN'
});
export const SL_mesheler_PN = new TileLayer({
    source: new WMS({
        projection: 'EPSG:3857',
        url: pathWms,
        params: {
            'LAYERS': 'mesheler_polygon',
            'FORMAT': 'image/png',
            'VERSION': '1.1.1',
            'TILED': true
        },
        serverType: 'geoserver',
        crossOrigin: 'anonymous'
    }),
    visible: true,
    name: 'SL_mesheler_PN'
});

export const SL_fiziki_daimiqar_LE = new TileLayer({
    source: new WMS({
        projection: 'EPSG:3857',
        url: pathWms,
        params: {
            'LAYERS': 'fiziki_daimiqar_line',
            'FORMAT': 'image/png',
            'VERSION': '1.1.1',
            'TILED': true
        },
        serverType: 'geoserver',
        crossOrigin: 'anonymous'
    }),
    visible: false,
    name: 'SL_fiziki_daimiqar_LE'
});
export const SL_geomorfoloji_LE = new TileLayer({
    source: new WMS({
        projection: 'EPSG:3857',
        url: pathWms,
        params: {
            'LAYERS': 'geomorfoloji_line',
            'FORMAT': 'image/png',
            'VERSION': '1.1.1',
            'TILED': true
        },
        serverType: 'geoserver',
        crossOrigin: 'anonymous'
    }),
    visible: false,
    name: 'SL_geomorfoloji_LE'
});
export const SL_iqlim_tempuratur_LE = new TileLayer({
    source: new WMS({
        projection: 'EPSG:3857',
        url: pathWms,
        params: {
            'LAYERS': 'iqlim_tempuratur_line',
            'FORMAT': 'image/png',
            'VERSION': '1.1.1',
            'TILED': true
        },
        serverType: 'geoserver',
        crossOrigin: 'anonymous'
    }),
    visible: false,
    name: 'SL_iqlim_tempuratur_LE'
});
export const SL_orografiya_silsileler_LE = new TileLayer({
    source: new WMS({
        projection: 'EPSG:3857',
        url: pathWms,
        params: {
            'LAYERS': 'orografiya_silsileler',
            'FORMAT': 'image/png',
            'VERSION': '1.1.1',
            'TILED': true
        },
        serverType: 'geoserver',
        crossOrigin: 'anonymous'
    }),
    visible: true,
    name: 'SL_orografiya_silsileler_LE'
});
//lines end
export const _coverMap = new TileLayer({
    source: new WMS({
        projection: 'EPSG:3857',
        url: pathWms,
        params: {
            'LAYERS': 'SpectraMap:_shared',
            'FORMAT': 'image/png',
            'VERSION': '1.1.1',
            'TILED': true
        },
        serverType: 'geoserver',
        crossOrigin: 'anonymous'
    }),
    visible: true,
    name: '_coverMap'
});

//WFS layers

//Vector layer of tourism historical monuments
export const Turizm_Abideler = new VectorLayer({
    source: new VectorSource(),
    zIndex: 3035,
    visible: true,
    name: 'Turizm_Abideler'
});
//Vector layer of tourism some interesting points
export const Turizm_Points = new VectorLayer({
    source: new VectorSource(),
    zIndex: 3036,
    visible: true,
    name: 'Turizm_Points'
});
// Vector layer of Fiziki points
export const Fiziki_Points = new VectorLayer({
    source: new VectorSource(),
    zIndex: 3037,
    visible: false,
    name: 'Fiziki_Points'
});
// Vector layer of ZooCografi points

export const Zoocografi_Points = new VectorLayer({
    source: new VectorSource(),
    zIndex: 3037,
    visible: false,
    name: 'Zoocografi_Points'
});
export const Torpaq_Points = new VectorLayer({
    source: new VectorSource(),
    zIndex: 3038,
    visible: false,
    name: 'Torpaq_Points'
});
//Fetching

fetch(pathWfs, {
    method: 'POST',
    body: new XMLSerializer().serializeToString(new WFS().writeGetFeature({
        featureNS: 'http://map.spectra.az',
        featurePrefix: 'SpectraMap',
        featureTypes: ['turizm_abideler'],
        outputFormat: 'application/json',
        srsName: 'EPSG:3857',
    })),
   
}).then((response) => {
    return response.json();
}).then(function (json) {
    const features = new GeoJSON().readFeatures(json);
    Turizm_Abideler.getSource().addFeatures(features);
    features.forEach((feature, res) => {
        feature.setStyle(new Style({
            image: new Icon(({
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction',
                src: feature.get('iconPath'),
                scale: 0.2
            }))
        }))
    })
});

fetch(pathWfs, {
    method: 'POST',
    body: new XMLSerializer().serializeToString(new WFS().writeGetFeature({
        featureNS: 'http://map.spectra.az',
        featurePrefix: 'SpectraMap',
        featureTypes: ['turizm_points'],
        outputFormat: 'application/json',
        srsName: 'EPSG:3857',
    }))
}).then((response) => {
    return response.json();
}).then(function (json) {
    const features = new GeoJSON().readFeatures(json);
    Turizm_Points.getSource().addFeatures(features);
    features.forEach((feature, res) => {
        feature.setStyle(new Style({
            image: new Icon(({
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction',
                src: feature.get('iconPath'),
                scale: 0.2
            }))
        }))
    })

});


fetch(pathWfs, {
    method: 'POST',
    body: new XMLSerializer().serializeToString(new WFS().writeGetFeature({
        featureNS: 'http://map.spectra.az',
        featurePrefix: 'SpectraMap',
        featureTypes: ['fiziki_points'],
        outputFormat: 'application/json',
        srsName: 'EPSG:3857',
    }))
}).then((response) => {
    return response.json();
}).then(function (json) {
    const features = new GeoJSON().readFeatures(json);
    Fiziki_Points.getSource().addFeatures(features);
    features.forEach((feature, res) => {
        feature.setStyle(new Style({
            image: new Icon(({
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction',
                src: feature.get('iconPath'),
                scale: 0.2

            }))
        }))
    })
});

fetch(pathWfs, {
    method: 'POST',
    body: new XMLSerializer().serializeToString(new WFS().writeGetFeature({
        featureNS: 'http://map.spectra.az',
        featurePrefix: 'SpectraMap',
        featureTypes: ['zoocografi_point'],
        outputFormat: 'application/json',
        srsName: 'EPSG:3857',
    }))
}).then((response) => {
    return response.json();
}).then(function (json) {
    const features = new GeoJSON().readFeatures(json);
    Zoocografi_Points.getSource().addFeatures(features);
    features.forEach((feature, res) => {
        feature.setStyle(new Style({
            image: new Icon(({
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction',
                src: feature.get('iconPath'),
                scale: 0.2
            }))
        }))
    })
});

fetch(pathWfs, {
    method: 'POST',
    body: new XMLSerializer().serializeToString(new WFS().writeGetFeature({
        featureNS: 'http://map.spectra.az',
        featurePrefix: 'SpectraMap',
        featureTypes: ['torpaq_point'],
        outputFormat: 'application/json',
        srsName: 'EPSG:3857',
    }))

}).then((response) => {
    return response.json();
}).then(function (json) {
    const features = new GeoJSON().readFeatures(json);
    Torpaq_Points.getSource().addFeatures(features);
    features.forEach((feature, res) => {
        feature.setStyle(new Style({
            image: new Icon(({
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction',
                src: feature.get('iconPath'),
                scale: 0.03
            }))
        }))
    })
});
//Cover mps
googleRoad.setZIndex(500);
googleSatellite.setZIndex(500);
bingMapsRoad.setZIndex(500);
bingMapsAerial.setZIndex(500);
GoMap.setZIndex(500);
SL_bitkiler_PN.setZIndex(1001);
SL_torpaq_PN.setZIndex(1002);
SL_fiziki_Elevation_LE.setZIndex(1003);
SL_geomorfoloji_PN.setZIndex(1004);
SL_iqlim_PN.setZIndex(1005);
SL_zocografi_PN.setZIndex(1006);
SL_zoocografi_qoruqlar_PN.setZIndex(1007);
SL_orografiya_dac_PN.setZIndex(1008);
SL_mesheler_PN.setZIndex(1009);
/*Lines Zindex setting*/
SL_fiziki_daimiqar_LE.setZIndex(2013);
SL_geomorfoloji_LE.setZIndex(2016);
SL_iqlim_tempuratur_LE.setZIndex(2017);
SL_orografiya_silsileler_LE.setZIndex(2019);
_coverMap.setZIndex(3027);
SL_geomorfoloji_relyef_PT.setZIndex(3029);
SL_torpaq_PT.setZIndex(3030);

