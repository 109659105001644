import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UpdateLegendStatus, UpdateMapMame, UpdateLanguage, UpdateLayers } from '../redux/Action'
import './css/AccordionMenu.css';
import axios from 'axios';

import { nodeServerAdress } from '../Helper/config';

//Material
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import {
    ArrowLeft,
    ArrowDropDown
} from '@material-ui/icons/';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import { PrettoSlider } from './Sliders';
import SwitchMap from '../Helper/SwichMap';

import * as allLayers from '../OpenLayers/Layers';


export class AccordionMenu extends Component {
    constructor() {
        super();
        this.state = {
            app_language: '',
            mnuAzMapState: false,
            mnuLayersState: false,
            mnuOtherMaps: false,
            MainMenuMap: [],
            MainMenuLayers: [],
            MainMenuApp: [],
            MainMenuOtherMap: [],
            MapName: '',
            appMenu: {
                azMapName: '',
                layersName: '',
                otherMaps: ''
            }
        }

    }
    resizeMenu = () => {
        let mnu = document.getElementById('menuPaper')
        if (mnu) {
            mnu.style.height = document.getElementById('map').offsetHeight - 90 + 'px';
        }
    }
    componentWillMount() {
        if (localStorage.getItem('app_lng')) {
            this.props.setLanguage(localStorage.getItem('app_lng'));
        } else {
            this.setState({ app_language: 'aze' })
        }
    }
    componentDidMount() {
        axios(nodeServerAdress + 'menu').then(response => {
            return response.data
        }).then(data => {
            this.setState({
                MainMenuMap: data.dataMenuMaps.rows,
                MainMenuLayers: data.dataMenuLayers.rows,
                MainMenuApp: data.dataMenuApp.rows,
                MainMenuOtherMap: data.dataMenuOtherMap.rows
            })
        })
        window.addEventListener('resize', () => {
            this.resizeMenu();
        })
        document.addEventListener('load', () => {
            alert()
        })
        //Resize the menu
        this.resizeMenu();
    }
    clickMapName = (layerName) => {
        this.props.setMapName(layerName);//Xerite adini redux store yazaq
        SwitchMap(layerName);
        let tempL = {};
        Object.values(allLayers).map(layer => {
            if (layer) {
                tempL[layer.get('name')] = layer.getVisible();
            }
        })
        this.props.UpdateLayers(tempL);
    }
    handleCheckBox = (name, value) => {
        let tempOb = this.props.Layers;
        tempOb[name] = value;
        this.props.UpdateLayers(tempOb);
        this.setState({});
        Object.values(allLayers).map(data => {
            if (data.get('name') !== undefined) {
                if (data.get('name') === name) {
                    data.setVisible(value);
                }
            }
        })
    }
    setLayerOpacity = (val) => {
        var perc = val / 100;
        allLayers.SL_bitkiler_PN.setOpacity(perc);
        allLayers.Zoocografi_Points.setOpacity(perc);
        allLayers.SL_zoocografi_qoruqlar_PN.setOpacity(perc);
        allLayers.SL_zocografi_PN.setOpacity(perc);
        allLayers.SL_torpaq_PN.setOpacity(perc);
        allLayers.SL_iqlim_PN.setOpacity(perc);
        allLayers.SL_iqlim_tempuratur_LE.setOpacity(perc);
        allLayers.SL_geomorfoloji_LE.setOpacity(perc);
        allLayers.SL_geomorfoloji_PN.setOpacity(perc);
        allLayers.SL_geomorfoloji_relyef_PT.setOpacity(perc);
        allLayers.SL_orografiya_dac_PN.setOpacity(perc);
        allLayers.SL_orografiya_silsileler_LE.setOpacity(perc);
        allLayers.Turizm_Abideler.setOpacity(perc);
        allLayers.Turizm_Points.setOpacity(perc);
        allLayers.Fiziki_Points.setOpacity(perc);
        allLayers.Torpaq_Points.setOpacity(perc);
        allLayers.SL_mesheler_PN.setOpacity(perc);
        allLayers._coverMap.setOpacity(perc);
    }
    sendLocationMethod = () => {
        return this.props.SetLocation()
    }
    sendPrtscrMethod = () => {
        return this.props.TakeImage()
    }
    render() {

        let az_xeritesi = '';
        let tebeqeler = '';
        let diger_xeriteler = '';
        let tebeq_sheffafligi = '';
        let yeriniz = '';
        let legend = '';
        let goruntu_al = '';
        let pocht = '';
        this.state.MainMenuApp.find(data => {
            data.Sira_nomresi === 1 ? az_xeritesi = data : data.Sira_nomresi === 2 ? tebeqeler = data : data.Sira_nomresi === 3 ? diger_xeriteler = data : data.Sira_nomresi === 4 ? tebeq_sheffafligi = data : data.Sira_nomresi === 5 ? yeriniz = data : data.Sira_nomresi === 6 ? legend = data : data.Sira_nomresi === 7 ? goruntu_al = data : pocht = data
        })
        return (
            <div style={styles.paper} id='menuCover'>
                <Paper style={{ border: 0, boxShadow: 'none' }} id='menuPaper'>
                    {/* Maps **********************************/}
                    <MenuItem style={{ marginBottom: '2px' }} onClick={
                        //butona basildigi zaman state deyisherek alt menularin achib baglayaq
                        () => { this.setState({ mnuAzMapState: !this.state.mnuAzMapState }) }
                    }>
                        <ListItemIcon >
                            <div>
                                <Typography style={{ color: '#656565' }} variant="subtitle1">{
                                    this.state.MainMenuApp.length > 0 ? az_xeritesi[this.props.lng === 'eng' ? 'name_eng' : this.props.lng === 'rus' ? 'name_rus' : 'name_az'] : < CircularProgress color="secondary" />
                                }</Typography>
                                {this.state.mnuAzMapState ? <ArrowDropDown style={{ position: 'absolute', right: '0', top: 12 }} /> : <ArrowLeft style={{ position: 'absolute', right: '0', top: 12 }} />}
                            </div>

                        </ListItemIcon>
                    </MenuItem>
                    {/* Eger state true ise gorsensin eks halda gorsenmesin */}
                    {this.state.mnuAzMapState ? (<MenuList >
                        {this.state.MainMenuMap.map((layerName, index) => {
                            return (
                                <MenuItem key={index} style={{ marginBottom: '1px' }} onClick={() => {
                                    this.clickMapName(layerName.name_az)
                                }}>
                                    <ListItemIcon >
                                        <img src={layerName.iconPath} alt='' style={{ maxHeight: '24px', maxWidth: '24px' }} />
                                    </ListItemIcon>
                                    <Typography variant="subtitle2">{this.props.lng === 'eng' ? layerName.name_eng : this.props.lng === 'rus' ? layerName.name_ru : layerName.name_az}</Typography>
                                </MenuItem>
                            )
                        })}
                    </MenuList>) : null}
                    {/* Leyers **********************************/}
                    <MenuItem onClick={() => { { this.setState({ mnuLayersState: !this.state.mnuLayersState }) } }} style={{ marginBottom: '2px' }}>
                        <ListItemIcon >
                            <div>
                                <Typography style={{ color: '#656565' }} variant="subtitle1">{
                                    //az_xeritesi[this.props.lng === 'eng' ? 'name_eng' : this.props.lng === 'rus' ? 'name_rus' : 'name_az'] 
                                    //Yuxarida elde edilen az_xeriteleri Objectinin programin az,rus ve eng dilene gore hansi propertiesini
                                    //getirmesi teleb olunur meselen az_xeriteleri[ 'name_az'] 
                                    this.state.MainMenuApp.length > 0 ? tebeqeler[this.props.lng === 'eng' ? 'name_eng' : this.props.lng === 'rus' ? 'name_rus' : 'name_az'] : < CircularProgress color="secondary" />
                                }</Typography>
                                {this.state.mnuLayersState ? <ArrowDropDown style={{ position: 'absolute', right: '0', top: 12 }} /> : <ArrowLeft style={{ position: 'absolute', right: '0', top: 12 }} />}
                            </div>
                        </ListItemIcon>
                    </MenuItem>
                    {this.state.mnuLayersState ? (
                        this.state.MainMenuLayers.map((data, index) => {
                            return (
                                <MenuItem key={index} style={{ marginBottom: '1px' }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox defaultValue={data.layer_name} checked={this.props.Layers[data.layer_name]} onChange={(e) => {
                                                this.handleCheckBox(data.layer_name, e.target.checked)
                                            }} />
                                        }
                                        label={this.props.lng === 'eng' ? data.name_eng : this.props.lng === 'rus' ? data.name_rus : data.name_az} />
                                </MenuItem>
                            )
                        })
                    ) : null}
                    {/* Other Maps */}
                    <MenuItem style={{ marginBottom: '2px' }} onClick={
                        //butona basildigi zaman state deyisherek alt menularin achib baglayaq
                        () => { this.setState({ mnuOtherMaps: !this.state.mnuOtherMaps }) }
                    }>
                        <ListItemIcon >
                            <div>
                                <Typography style={{ color: '#656565' }} variant="subtitle1">{
                                    this.state.MainMenuApp.length > 0 ? diger_xeriteler[this.props.lng === 'eng' ? 'name_eng' : this.props.lng === 'rus' ? 'name_rus' : 'name_az'] : < CircularProgress color="secondary" />
                                }</Typography>
                                {this.state.mnuOtherMaps ? <ArrowDropDown style={{ position: 'absolute', right: '0', top: 12 }} /> : <ArrowLeft style={{ position: 'absolute', right: '0', top: 12 }} />}
                            </div>
                        </ListItemIcon>
                    </MenuItem>
                    {/* Eger state true ise gorsensin eks halda gorsenmesin */}
                    {this.state.mnuOtherMaps ? (<MenuList >
                        {this.state.MainMenuOtherMap.map((layerName, index) => {
                            return (
                                <MenuItem key={index} style={{ marginBottom: '1px' }} onClick={() => {
                                    //Gonderilen xeritenin adini bilmek uchun
                                    //hemin xeritenin adini azerbaycan dilinde gonderek
                                    //chunki gonderilen bu ada gore yoxlayib xerite chagiralacaq
                                    this.clickMapName(layerName.name_az);
                                }}>
                                    <ListItemIcon >
                                        <img src={layerName.iconPath} alt='' style={{ maxHeight: '24px', maxWidth: '24px' }} />
                                    </ListItemIcon>
                                    <Typography variant="subtitle2">{this.props.lng === 'eng' ? layerName.name_eng : this.props.lng === 'rus' ? layerName.name_ru : layerName.name_az}</Typography>
                                </MenuItem>
                            )
                        })}
                    </MenuList>) : null}
                    <Paper style={{ padding: 16, border: '0', boxShadow: 'none' }}>
                        <Typography style={{ color: '#656565' }} variant="subtitle1">{
                            //az_xeritesi[this.props.lng === 'eng' ? 'name_eng' : this.props.lng === 'rus' ? 'name_rus' : 'name_az'] 
                            //Yuxarida elde edilen az_xeriteleri Objectinin programin az,rus ve eng dilene gore hansi propertiesini
                            //getirmesi teleb olunur meselen az_xeriteleri[ 'name_az'] 
                            this.state.MainMenuApp.length > 0 ? tebeq_sheffafligi[this.props.lng === 'eng' ? 'name_eng' : this.props.lng === 'rus' ? 'name_rus' : 'name_az'] : < CircularProgress color="secondary" />
                        }</Typography>
                        <PrettoSlider valueLabelDisplay="auto" aria-label="pretto slider" defaultValue={100} style={{ width: '90%' }} onChange={(e, val) => {
                            this.setLayerOpacity(val)
                        }} />
                    </Paper>
                    {/* yeriniz */}
                    <MenuItem style={{ marginBottom: '2px' }} onClick={
                        this.sendLocationMethod
                    }>
                        <ListItemIcon >
                            <Typography style={{ color: '#656565' }} variant="subtitle1">{
                                this.state.MainMenuApp.length > 0 ? yeriniz[this.props.lng === 'eng' ? 'name_eng' : this.props.lng === 'rus' ? 'name_rus' : 'name_az'] : < CircularProgress color="secondary" />
                            }</Typography>
                        </ListItemIcon>
                    </MenuItem>
                    {/* Take image */}
                    {/* <MenuItem style={{ marginBottom: '2px' }} onClick={
                        //butona basildigi zaman state deyisherek alt menularin achib baglayaq
                        () => {
                            this.sendPrtscrMethod();
                        }
                    }>
                        <ListItemIcon >
                            <Typography style={{ color: '#656565' }} variant="subtitle1">{
                                this.state.MainMenuApp.length > 0 ? goruntu_al[this.props.lng === 'eng' ? 'name_eng' : this.props.lng === 'rus' ? 'name_rus' : 'name_az'] : < CircularProgress color="secondary" />
                            }</Typography>
                        </ListItemIcon>
                    </MenuItem> */}
                    {/* Legend*/}
                    <MenuItem style={{ marginBottom: '2px' }} onClick={
                        //butona basildigi zaman state deyisherek alt menularin achib baglayaq
                        () => { this.props.setLegendStatus(!this.props.legendStatus) }
                    }>
                        <ListItemIcon >
                            <Typography style={{ color: '#656565' }} variant="subtitle1">{
                                this.state.MainMenuApp.length > 0 ? legend[this.props.lng === 'eng' ? 'name_eng' : this.props.lng === 'rus' ? 'name_rus' : 'name_az'] : < CircularProgress color="secondary" />
                            }</Typography>
                        </ListItemIcon>
                    </MenuItem>
                    {/* Pocht*/}
                    <MenuItem style={{ marginBottom: '2px' }} onClick={
                        //butona basildigi zaman state deyisherek alt menularin achib baglayaq
                        () => { }
                    }>
                        <Link href={'mailto:info@spectra.az'} style={{ width: '100%', color: '#656565' }}>
                            {this.state.MainMenuApp.length > 0 ? pocht[this.props.lng === 'eng' ? 'name_eng' : this.props.lng === 'rus' ? 'name_rus' : 'name_az'] : < CircularProgress color="secondary" />}
                        </Link>
                    </MenuItem>
                </Paper>
            </div>

        )
    }
}
const styles = {
    paper: {
        position: 'absolute',
        width: 320,
        right: 10,
        top: 70,
        overflowY: 'auto',
        zIndex: 4,
        backgroundColor: '#FFFFFF',
        padding: '6px',
    }
}

export default connect((state) => { return { legendStatus: state.legendStatus.status, mapName: state.mapName.name, lng: state.lng.name, Layers: state.mapLayers.Layers } }, { setLegendStatus: UpdateLegendStatus, setMapName: UpdateMapMame, setLanguage: UpdateLanguage, UpdateLayers: UpdateLayers })(AccordionMenu)
