import axios from 'axios';
import { nodeServerAdress } from '../Helper/config';
export const Fetching = (name, that) => {
    name = name.trim();
    if (name === 'Bitki örtüyü xəritəsi') {
        axios.get(nodeServerAdress + 'bitki').then(response => {
            //Birkilerde point tipli objekt olmadigi uchun legend -in statinden
            //legendPointData sifirlayaqki evvelki xeritelerin requestlerinden
            //eger legendPointData doldurulubsa, bu requeste gelmesin
            that.setState({ legendPolygonData: response.data.rows, legendPointData: [] })
        })
    } if (name === 'Turistik xəritə') {
      
        axios.get(nodeServerAdress + 'trsmPnts').then(response => {
            let tempData = response.data.rows;        
            tempData.unshift({ fname: "Memarlıq abidələri",iconPath: "./Images/MapIcons/Tourism/tourism_icons/college64.png"})
            that.setState({ legendPointData: tempData, legendPolygonData: [] })
        })

    } if (name === 'Geomorfoloji xəritə') {
        axios.get(nodeServerAdress + 'geomorfoloji').then(response => {
            //Bu metodun chagirldigi componentin (Legend) statini
            //axiosdan gelen data ile set edek  
            let tempOb = [];
            tempOb.push(
                {
                    fname: 'Becend relyef',
                    iconPath: './Images/MapIcons/Geomorfoloji/Points/becent.png'
                }
            )
            tempOb.push(
                {
                    fname: 'Kar, sirklər',
                    iconPath: './Images/MapIcons/Geomorfoloji/Points/KarSirkler.png'
                }
            )
            tempOb.push(
                {
                    fname: 'Lava cınqıllıqlar',
                    iconPath: './Images/MapIcons/Geomorfoloji/Points/LavaChinqillari.png'
                }
            )
            tempOb.push(
                {
                    fname: 'Səpintilər, ufantılar',
                    iconPath: './Images/MapIcons/Geomorfoloji/Points/sepintilerUfantilar.png'
                }
            )
            tempOb.push(
                {
                    fname: 'Səthi açılmış lakkolitlər və ekstruziyalar',
                    iconPath: './Images/MapIcons/Geomorfoloji/Points/sethiAcilmislak.png'
                }
            )
            tempOb.push(
                {
                    fname: 'Şlak, lava -şlak konusları',
                    iconPath: './Images/MapIcons/Geomorfoloji/Points/shlak.png'
                }
            )
            tempOb.push(
                {
                    fname: 'Sürüşmələr',
                    iconPath: './Images/MapIcons/Geomorfoloji/Points/surushkenler.png'
                }
            )
            tempOb.push(
                {
                    fname: 'Relyefdə təzahur edən tektonik laylar',
                    iconPath: './Images/MapIcons/Geomorfoloji/Line/rel_tez_tek_laylar.png'
                }
            )
            that.setState({ legendPolygonData: response.data.rows, legendPointData: tempOb })
        })

    } if (name === 'İqlim xəritəsi') {
        axios.get(nodeServerAdress + 'iqlim').then(response => {
            //Bu metodun chagirldigi componentin (Legend) statini
            //axiosdan gelen data ile set edek  
            that.setState({ legendPolygonData: response.data.rows, legendPointData: [] })
        })

    } if (name === 'Orografiya xəritəsi') {
        axios.get(nodeServerAdress + 'fizikiPoint').then(response => {
            // fname: "Civə"
            // iconPath: "../Images/MapIcons/Fiziki/cive-filizleri.png"
            let tempOb = response.data.rows;
            tempOb.push(
                {
                    fname: 'Silsilələr',
                    iconPath: './Images/MapIcons/Orographic/line/silsileler.png'
                }
            )
            tempOb.push(
                {
                    fname: 'Dağ arası çökəkliklər',
                    iconPath: './Images/MapIcons/Orographic/poly/dagarasichokeklikler.png'
                }
            )
            that.setState({ legendPolygonData: [], legendPointData: tempOb })
        })

    } if (name === 'Torpaqların ekoloji qiymətləndirməsi') {
        axios.get(nodeServerAdress + 'torpaqPolygon').then(response => {
            //Bu metodun chagirldigi componentin (Legend) statini
            //axiosdan gelen data ile set edek  
            return response.data.rows;
        }).then(polygonData => {
            //Legend Componentinin 2 olmaq uzere point tipli ve polygon tipli
            //datalari saxlayam stateleri var. Iknici stati da buradan set edek
            axios.get(nodeServerAdress + 'torpaqPoint').then(response => {
                that.setState({ legendPolygonData: polygonData, legendPointData: response.data.rows })
            })
        })

    } if (name === 'Zoocografi xəritə') {
        axios.get(nodeServerAdress + 'zooPoint').then(response => {
            //Bu metodun chagirldigi componentin (Legend) statini
            //axiosdan gelen data ile set edek  
            return response.data.rows;
        }).then(pointData => {
            //Legend Componentinin 2 olmaq uzere point tipli ve polygon tipli
            //datalari saxlayam stateleri var. Iknici stati da buradan set edek
            axios.get(nodeServerAdress + 'zooPolygon').then(response => {
                that.setState({ legendPolygonData: response.data.rows, legendPointData: pointData })
            })
        })
    }
}