import React, { Component } from 'react'
//css
import './CSS/PopUpTourismIcons.css'
export class PopUpTourismPoints extends Component {
    constructor() {
        super();
        this.state = {
            name: ''
        }
    }
    componentWillReceiveProps(nextporps) {
        if (nextporps.name !== this.state.name) {
            this.setState({ name: nextporps.name })
        }
    }
    render() {
        return (
            <div id='popupWrapTrsmIncs'>
                <div id='closePanel'>
                    <div id='closButton' onClick={(e) => {
                        document.getElementById('popupWrapTrsmIncs').style.display = '';
                    }
                    }>x</div>
                </div>
                <div id='popContent' className='trsmPntsTextAditional'>
                    <span id='trsmPntsText'>{this.state.name}</span>
                </div>
            </div>
        )
    }
}

export default PopUpTourismPoints
